.container-fluid.main {
    min-height: 100vh;
}

.game-icon::before {
    font-size: 150%;
}

.game-icon.size3x::before {
    font-size: 300%;
}

.game-icon.fw::before {
    float: left;
    table-layout: fixed;
    width: 30px;
    text-align: center;
}

.game-icon.fw.size3x::before {
    width: 60px;
}

.game-layout-start {
    width: 30%;
    min-width: 150px;
    max-width: 400px;
    transition: width .26s, opacity .26s;
}

.game-layout-center {
    transition: width .26s, opacity .26s;
}

.game-layout-end {
    width: 30%;
    min-width: 80px;
    max-width: 400px;
    transition: width .26s, opacity .26s;
}

.focus-left {
    .game-layout-start {
        width: 100%;
        max-width: 100%;
    }
    .game-layout-center, .game-layout-end {
        opacity: 0;
        width: 0;
        max-width: 0;
        display: none;
    }
}

.focus-right {
    .game-layout-end {
        width: 100%;
        max-width: 100%;
    }
    .game-layout-center, .game-layout-start {
        opacity: 0;
        width: 0;
        max-width: 0;
        display: none;
    }
}

.player-view {
    cursor: pointer;

    & > * {
        width: 100%;
        height: 100%;
    }
}

.modal-body hr {
    border-color: $black;
}

.alert-dark {
    background-color: $lighter-10;
    color: $white;
}

.text-lighter {
    color: $gray-500;
}

.bg-lighter-2 {
    background-color: $lighter-2;
}

.bg-lighter-5 {
    background-color: $lighter-5;
}

.bg-lighter-10 {
    background-color: $lighter-10;
}

.bg-darker-5 {
    background-color: $darker-5;
}

.list-group-item.active button.btn-secondary {
    background-color: $white;
    color: $black;
    border-color: $white;

    &:hover {
        background-color: $gray-300;
    }
}

.twitch {
    position: absolute;
    left: 50%;
    margin-top: -10px;

    &.on {
        color: $twitch;
    }

    &.off {
        color: $gray-700;
    }
}

.healthbar {
    
    width: 100%;
    height: 3px;

    .progress-bar {
        height: 3px;
    }

    &.Good .progress-bar {
        background-color: $success;
    }
    &.Fair .progress-bar {
        background-color: $info;
    }
    &.Poor .progress-bar {
        background-color: $warning;
    }
    &.VeryPoor .progress-bar, &.NearDeath .progress-bar {
        background-color: $danger;
    }
    &.Dead .progress-bar {
        background-color: $black;
    }
}

.repair-status {
    width: 100%;
    height: 3px;
    .progress-bar {
        background-color: $warning;
    }
}

.location-view {
    width: 0;
    transition-property: width;
    transition-duration: 0.3s;
    transition-delay: 0.2s;

    &.active {
        width: 320px;
    }
}

.trail-view {
    min-height: 320px;
}

.event-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    color: $white;
}

@media screen and (max-height: 700px) {
    .bottom-messages-view {
        display: none !important;
    }
}

@keyframes text-move-up {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 0;
        transform: translateY(-2rem);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

.text-move-up {
    opacity: 0;
    transform: translateY(0);
    position: absolute;
    animation: text-move-up 2s;
}

.maplabel {
    margin-top: 1.5rem;
}

.text-rotate-neg45 {
    rotate: -45deg;
}

.avatar-image {
    transition: filter 0.26s;

    &.monochrome {
        filter: grayscale(1);
    }

    &.blurred {
        filter: blur(5px);
    }
    
    &.large.blurred {
        filter: blur(20px);
    }

}

.roadview {
    width: 100%;
    height: 10rem;

    .ground {
        border: solid 4px;
        border-color: green;
        transition: all 0.3s;

        &.snowy {
            border-color: white;
        }
    }

    .wagon {
        padding-right: 3rem;

        & > div {
            display: inline-block;
            position: relative;
            transition: all 0.3s;
        }
    }
}