@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/_variables';

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #fc2535; //#dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   lighten(#198754, 16%);
$teal:    #20c997;
$cyan:    #0dcaf0;
$twitch:  #6441a4;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$darker-5: darken($dark, 5%);
$lighter-2: lighten($dark, 2%);
$lighter-5: lighten($dark, 5%);
$lighter-10: lighten($dark, 10%);

$modal-content-bg: $dark;
$modal-content-color: $white;
$modal-header-border-color: $darker-5;
$modal-footer-bg: $darker-5;
$modal-footer-border-color: $darker-5;
$modal-backdrop-opacity: 0.85;

$input-color: $white;
$input-placeholder-color: $gray-600;
$input-bg: $lighter-5;
$input-border-color: $darker-5;
$input-focus-color: $white;
$input-focus-bg: $darker-5;

$form-select-color: $white;
$form-select-bg: $lighter-10;
$form-select-border-color: $lighter-5;

$form-check-input-bg: $lighter-10;
$form-check-input-checked-bg-color: $primary;

$input-group-addon-color: $white;
$input-group-addon-bg: $gray-600;
$input-group-addon-border-color: $dark;

$list-group-color: $white;
$list-group-bg: $lighter-5;
$list-group-hover-bg: $darker-5;

$card-color: $white;
$card-bg: $lighter-5;

$toast-header-color: $white;
$toast-header-background-color: $black;
$toast-max-width: 50vw;

$progress-bg: $darker-5;

@import '~bootstrap/scss/bootstrap.scss';
@import './custom.scss';